<template>
  <div class="kms-box">
    <Header/>
    <new-home-header title="KMS知识管理系统" @btnClick="btnClick" :content="content"
                     :imgSrc="`${require('@/assets/img/payImages/KMSk.png')}`" imgStyle="height:400px"/>
    <div class="page-content">
      <div class="card-title">目前企业面临知识管理痛点</div>
      <div class="item-list">
        <div class="quest-item-box" v-for="item in questList" :key=item.background
             :style="{backgroundColor:item.background}">
          <div class="item-img" :style="{backgroundColor:item.img}">
            <span>{{ item.istr }}</span>
          </div>
          <div class="str-box">
            <span>{{ item.str1 }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="wms-box">
      <div class="card-title">全方位满足企业知识管理需求</div>
      <div class="logistics-box">
        <div class="logistics-box-cell" v-for="(item,i) in logistics" :key="i">
          <div class="logistics-box-cell-title">
            <div class="logistics-box-cell-title-left">{{ item.title }}</div>
            <div class="logistics-box-cell-title-right">{{ item.tips }}</div>
          </div>
          <div class="logistics-box-content">{{ item.content }}</div>
        </div>
      </div>
    </div>
    <div class="wms-box1">
      <div class="card-title">助力企业提升知识管理水平</div>
      <div class="foot-help-list">
        <div class="help-item" v-for="item in helpList" :key="item.str2">
          <img :src="`${require('@/assets/img/payImages/'+item.img)}`" class="img-foot">
          <div class="str1">
            <span>{{ item.str1 }}</span>
          </div>
          <div class="str2">
            <span>{{ item.str2 }}</span>
          </div>
        </div>
      </div>
    </div>
    <applyTrial ref="dialog" :servicePlatform="'KMS知识管理系统'"/>
    <Footer/>
  </div>
</template>
<script>
import bannerList from "@/components/assembly/bannerList.vue";
import newHomeHeader from "@/components/assembly/newHomeHeander";
import Header from "@/views/home/newCloudeHeader.vue";
import applyTrial from "@/components/assembly/applyTrial";
import Footer from "@/views/home/newCloudeFooter.vue";

export default {
  name: "kmsKnowledge",
  components: {bannerList, newHomeHeader, Header, applyTrial, Footer},
  methods: {
    btnClick() {
      this.$refs.dialog.show = true;
    }
  },
  data() {
    return {
      questList: [
        {
          background: 'rgba(229, 233, 255, 1)',
          img: 'rgba(25, 53, 222, 1)',
          str1: '知识分散在个人电脑和应用系统中，无法集中管理',
          istr: '散'
        },
        {
          background: 'rgba(255, 226, 229, 1)',
          img: 'rgba(254, 39, 59, 1)',
          str1: '缺乏公司级统一的知识分类体系，多头维护，目录混乱',
          istr: '乱'
        },
        {
          background: 'rgba(255, 244, 228, 1)',
          img: 'rgba(255, 152, 0, 1)',
          str1: '存在知识壁垒，跨部门、跨项目知识共享和协作困难',
          istr: '困'
        },
        {
          background: 'rgba(226, 248, 255, 1)',
          img: 'rgba(91, 218, 255, 1)',
          str1: '缺乏与专家进行知识交流的途径，知识使用者难以加深对知识的理解',
          istr: '孤'
        },
        {
          background: 'rgba(231, 226, 255, 1)',
          img: 'rgba(81, 47, 228, 1)',
          str1: '知识只进不出，精准推送和场景化应用困难',
          istr: '难'
        },
        {
          background: 'rgba(255, 230, 222, 1)',
          img: 'rgba(255, 60, 0, 1)',
          str1: '知识缺乏有效的审核和维护，过期和错误的知识容易误导使用者',
          istr: '误'
        },
      ],
      logistics: [
        {
          title: '统一知识搜索',
          tips: '知识查找效率提升100%',
          content: '建立统一检索平台，帮助员工快速、准确地找到所需知识；提供关键字搜索、组合条件搜索等多种知识检索方式，以及热门关键词、搜索联想词等多种功能'
        },
        {
          title: '多岗位知识地图',
          tips: '一键获取所需资料',
          content: '企业可将不同岗位、角色所需掌握的知识梳理成学习地图，为员工查找知识提供导航；融合闯关式、答题竞赛式游戏化学习模式，增强员工学习知识的趣味性'
        },
        {
          title: '多维度知识库',
          tips: '知识资产科学统一管理',
          content: '建立统一检索平台，帮助员工快速、准确地找到所需知识；提供关键字搜索、组合条件搜索等多种知识检索方式，以及热门关键词、搜索联想词等多种功能'
        },
        {
          title: '多业务知识专题',
          tips: '知识利用效率倍增',
          content: '根据业务场景设计产品、营销、项目、质量专辑，将各业务线常用知识集中化管理，提升知识利用效率，为业务赋能'
        },
        {
          title: 'ISO文件全周期管理',
          tips: '保障质量管理高效合规',
          content: '通过对ISO文件创建→审批→发布→更新→调阅→回收→废止→培训的全过程管理，实现ISO体系文件的信息化、流程化、安全管控，提升ISO质量体系管理工作效率及合规性'
        },
        {
          title: '信息情报库',
          tips: '高效获取外部资源',
          content: '采用互联网爬虫技术，对接相关外部系统、数据库，实现相关情报的自动采集及内容识别，高效获取外部资源，便于组织及时了解外部动态，提升市场竞争能力'
        },
        {
          title: '数智化培训学习',
          tips: '赋能企业人才培育',
          content: '培训-学习-考试-评分-归档全程在线，个性化定制学习路径，在线课堂、在线直播、答疑竞赛、移动学习多位一体'
        },
        {
          title: '智能知识图谱',
          tips: '知识采集、加工、应用更简单',
          content: '提供一种从海量文本和图像中抽取结构化知识的手段，实现知识智能采集、加工、搜索、推荐、推送、问答等知识应用场景'
        },
      ],
      helpList: [
        {img: 'ys01.png', str1: '知识全周期管理', str2: '对知识从沉淀到共享、查找、复用、创新进行全周期管理'},
        {img: 'ys02.png', str1: '业界标杆对标', str2: '多年来服务了各大行业标杆企业，可为企业提供相应的对标案例实践'},
        {img: 'ys03.png', str1: '科学体系支撑', str2: '依托国内外权威知识方法论，为企业搭建科学、合理的知识管理体系'},
        {img: 'ys04.png', str1: '系统快速落地', str2: '基于数智大脑成熟的KMS平台，可灵活部署各类应用模块，实现快速落地建设'},
        {img: 'ys05.png', str1: '立体服务模式', str2: '提供精准“咨询+IT”服务模式，确保知识管理逐渐深入企业的业务核心'},
        {img: 'ys06.png', str1: '知识持续增值', str2: '提供MIKE最佳实践和对标参考，让企业能够充分学习优秀的企业KM实践经验'},
      ],
      content: 'KMS系统适用于管理知识资源的软件系统，包括知识库管理、知识分类和标签、搜索引擎、协作与分享等功能模块，帮助企业提高知识管理和利用效率。',
    }
  }
}
</script>
<style lang="scss" scoped>
.foot-help-list {
  width: 1200px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .help-item {
    margin-bottom: 12px;
    width: 350px;
    height: 275px;
    border: 1px solid #DDDDDD;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 0 20px;

    .img-foot {
      width: 130px;
      height: 110px;
    }

    .str1 {
      font-size: 18px;
      font-family: 'SourceHanSansCN';
    }

    .str2 {
      font-size: 16px;
      font-family: 'SourceHanSansCN';
      text-align: center;
    }
  }
}

.wms-box {
  padding: 60px 0;
  background-color: rgba(247, 248, 254, 1);
}

.wms-box1 {
  padding: 60px 0;
}

.card-title {
  font-size: 30px;
  font-weight: bold;
  color: #333;
  text-align: center;
  padding-bottom: 30px;
}

.logistics-box {
  width: 1200px;
  margin: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  .blue-icon {
    width: 80px;
    height: 80px;
    background: #1935DE;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
  }

  &-cell {
    width: 290px;
    height: 290px;
    box-sizing: border-box;
    background-color: #fff;
    padding: 30px 30px 0;
    margin-bottom: 12px;

    &-title {
      font-size: 18px;

      &-left {
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
      }

      &-right {
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
      }
    }

    .logistics-box-content {
      color: rgba(51, 51, 51, 1);
      font-size: 16px;
      padding-top: 10px;
      margin-top: 10px;
      border-top: 1px solid rgba(25, 53, 222, 1);
      line-height: 1.8;
      font-family: 'SourceHanSansCN';
    }
  }
}

.page-content {
  padding: 60px 0;
}

.quest-item-box {
  width: 350px;
  height: 60px;
  padding: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .item-img {
    width: 60px;
    height: 60px;
    font-size: 24px;
    font-family: 'SourceHanSansCN';
    flex-shrink: 0;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .str-box {
    margin-left: 20px;
    font-size: 16px;
    font-family: 'SourceHanSansCN';
    font-weight: 500;
    color: #333333;
    line-height: 24px;
    display: flex;
    flex-direction: column;
  }
}

.item-list {
  width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.kms-box {
  min-width: 1360px;
}
</style>
<style scoped>
.kms-box >>> .banner-card-container .right-imgBox {
  position: relative;
  top: 12px;
}
</style>
